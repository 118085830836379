import React, { Component } from 'react';

// Router
import {
  Link
} from 'react-router-dom';

// Conf
import conf from './_core/_conf';

// Core
import Site from './_core/components/Site';
import Loader from './_core/components/Loader';
import {withData} from './_core/utils';

// Project
import * as Project from './components';


class Index extends Component {


  constructor(props){

      super(props);
  }

  componentWillMount = () => {

      const _this = this;

  }



  render() {

    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    
    return (
      <Site>

        {/* Loader - loads the manifest from settings, then outputs the page */}
        <Loader manifest={settings.MANIFEST}
            loaded={
              () => (

                <Project.Router />
                
              )
          }
        />

      </Site>
    );
  }
}



export default withData(Index);
