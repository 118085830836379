import React, { Component } from 'react';

import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import Vimeo from '../_core/components/Vimeo';
import Content from '../_core/components/Content';
import Nl2br from '../_core/components/Nl2br';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Template from '../_core/components/Template';
import ScrollTo from '../_core/components/ScrollTo';
import Toggle from '../_core/components/Toggle';


// Models
import Asset from '../_core/models/Asset';
import Entry from '../_core/models/Entry';
import Data from '../_core/models/Data';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		

		return (
			
			<Template modifiers={['default']}>

				{/* Scroll to top */}
				<ScrollTo pos={0} update={window.location.href} />
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />

				{/* Template */}
				<FadeIn>
					

					{/* Body */}
					<div className="Template-body">
						<section className="Section Section--sticky Section--white">
							

							<div className="Section-sticky">
								
									<hgroup className="Section-title">
										<div className="Container">
											<h2>Page Not Found</h2>
											
										</div>
									</hgroup>
								
							</div>

							<div className="Container">
							<div className="Section-body">
								
								

									<Content modifier="default">
										<p>Sorry, we cannot find the page you requested. It may have been removed or relocated.</p>
										<p>Try browsing from our <a href="/">Home page</a>. If you feel there should be a page here, please contact us.</p>
									</Content>

									

								</div>
							</div>
						</section>
					</div>

					
				</FadeIn>
			</Template>
		
		);

	}
}

export default Default;
