import React, { Component } from 'react';
import {Helmet} from "react-helmet";

//import {ScrollTo,Nl2br,Burger,Grid,Icon,SiteModifier,Link,LoaderLink,Loader,Window,Media,Section,Content,ModifierSection} from '../_core/components';
import Template from '../_core/controllers/Template';

import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import Vimeo from '../_core/components/Vimeo';
import Content from '../_core/components/Content';
import Nl2br from '../_core/components/Nl2br';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import ScrollTo from '../_core/components/ScrollTo';
import Toggle from '../_core/components/Toggle';
import Link from '../_core/components/Link';
import Burger from '../_core/components/Burger';
import Icon from '../_core/components/Icon';

// Breakpoints
import {Breakpoints} from '../_core/utils';

// Models
import Asset from '../_core/models/Asset';
import Entry from '../_core/models/Entry';
import Data from '../_core/models/Data';

// Settings
import settings from '../_settings';

// Project
import * as Project from '../components';

class Layout extends Component {

  isBooking = false;

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {


		return (
		<React.Fragment>
		<Data require={['ENTRY']}>
			{({ENTRY,navigation,entries,background,globals,menus})=>{

			return (
			<div className="Site-page">

        {(()=>{

            if(window.location.hash.match('#book') && !this.isBooking){

                this.isBooking = true;

                // Launch the overlay
                if(window.Calendly) window.Calendly.initPopupWidget({url: 'https://calendly.com/pattibuttrick/consultation-call'});
                
                setTimeout(()=>{
                  this.isBooking = false;
                },500);

            }

        })()}



				<ScrollTo useHistory={true} ignore={()=>window.location.hash.match('#book')} auto />

				{/* Logo */}
              <div className="Site-logo">
                <div className="Container"><Link to={'/'} deactivate="#nav">{settings.SITENAME}</Link></div>
              </div>

              	{/* Burger */}
              	<Burger glyph />
              


				{/* Navigation */}
              <div className="Site-nav">
              	<div className="Container">
              		
              		{/* Nav Links */}
              		<SiteModifier demodifier={'menu'}>
	                <ul className="List List--nav" id="nav">
	                	{menus.find(m=>m.slug == 'main').sets[0].links.map((item,ix)=>(
                        	<li key={ix}><Link navlink to={item.uri} exact={item.uri == '/'} navlink >{item.text}</Link></li>
                    	))}
	                </ul>
	                </SiteModifier>

	            	{/* Quick contact */}
	                <div className="Site-contact">
	                	<ul className="List List--icons">
	                		{globals.links.find(l=>l.label == 'contact').links.map((item,ix)=>(
		                  		<li key={ix}><a href={item.customLink} target="_blank"><Icon type={item.text} /></a></li>
		                  	))}
		                </ul>
	                </div>
                </div>
              </div>

              	{/* Template */}
              <div className="Site-template">
                 
              		<Template />
         	 
              </div>

              	

	           	{/* Footer */}
              <div className="Site-foot">
              	<div className="Container">
	                <ul className="List List--nav">
	                	{menus.find(m=>m.slug == 'footer').sets[0].links.map((item,ix)=>(
                        	<li key={ix}><Link to={item.uri}>{item.text}</Link></li>
                    	))}
	                </ul>
	            </div>

	            <div className="Site-signoff">
	            	<div className="Container">
	            	<Content modifier="signoff">
	            		<h2>Patti Buttrick</h2>
	            		<h3>{globals.siteStrapline}</h3>
	            		<p><Nl2br text={globals.siteSignoff} /></p>
                  <p>© {new Date().getFullYear()}</p>
	            	</Content>
	            	</div>
	            </div>

	            </div>



            </div>
          )}}
		</Data>
		<Data require={['ENTRY']}>
            {({ENTRY,DETAILS,DETAIL,FILTERING,FILTER,SEARCH})=>{


              let META = ENTRY;
              if(FILTERING && FILTER) META = FILTER;
              if(DETAILS && DETAIL) META = DETAIL;
              if(SEARCH) META = {...META,metaTitle:'Search results for '+SEARCH};
              if(META){
                return (

                  <Helmet>
                    <meta property="og:description" content={META.metaDescription}/>
                    <meta name="description" content={META.metaDescription} />
                    <meta name="twitter:description" content={META.metaDescription} />
                    <title>{((META.metaTitle) ? META.metaTitle : META.title)} | {settings.SITENAME}</title>
                    {(()=>{

                      if(META.hasOwnProperty('schema') && META.schema){

                        return (
                          <script type="application/ld+json">{JSON.stringify(META.schema)}</script>
                        )
                      }else{
                        return  null;
                      }

                    })()}
                  </Helmet>

                )
              }else{
                return null;
              }

            }}
            </Data>
            </React.Fragment>

		);

	}
}

export default Layout;
