import React, { Component } from 'react';
import PubSub from 'pubsub-js';


let DATA = {};

const withData = (WrappedComponent, listeners, triggers) => {
  return class DataStore extends Component {

    constructor(props) {
      super(props);
      this.state = {data:DATA};
  
    }

   

  componentDidMount() {

      const _this = this;
      PubSub.subscribe( 'store', (label,data) => {
         
          _this.setState({data:DATA});

      });

  }

  store = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      DATA[label] = item;
      //this.setState({data:DATA});

      if(publish) this.publish();
  }

  unstore = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      if(!DATA.hasOwnProperty(label)) return;

      delete DATA[label];
      //this.setState({data:DATA});

      if(publish) this.publish();
  }

  merge = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      let cur = DATA[label];
   
      let merge = [];
      if(typeof cur != 'undefined'){
        if(!Array.isArray(cur)) merge = [cur];
        else merge = cur;
        merge = [...merge,item];
      }else{

        merge = [item];

      }
      DATA[label] = merge;


      //this.setState({data:DATA});

      if(publish) this.publish();
  }

  unmerge = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
     if(!DATA.hasOwnProperty(label)) return;
     let _this = this;
      DATA[label] = DATA[label].filter((i) => (!_this.objCompare(i,item)));
      if(!DATA[label].length) delete DATA[label];
      
      //this.setState({data:DATA});

      if(publish) this.publish();
  }

  publish = () => {
      console.log('PUBLISH');
      //PubSub.publish('store',DATA);
       this.setState({data:DATA});

  }

  get = (label,data) => {
  	 
      if(DATA.hasOwnProperty(label)) return DATA[label];

  }

  objCompare = (a,b) => {

    return (JSON.stringify(a) === JSON.stringify(b));

  }

  equals = (label,data) => {

      if(!DATA.hasOwnProperty(label)) return false;
      let val = DATA[label];

      let match  = (this.objCompare(val,data));
      return match;

  }

  contains = (label,data) => {

      if(!DATA.hasOwnProperty(label)) return false;
      let val = DATA[label];



      if(!Array.isArray(val)) return this.objCompare(val,data);

      let matches = 0;
      val.forEach((i)=>{

          if(this.objCompare(i,data)) matches++;

      })
      return matches > 0;

  }


  componentWillUnmount() {



    }


    render() {
      
      return (
        <WrappedComponent ref="WrappedComponent" datastore={this} data={this.state.data} { ...this.props } />
      );
    }
  }
}

export default withData;