import React, { Component } from 'react';

//import {Html,LoaderLink,Vimeo,Slider,Toggle,ViewportProgress,Parallax,Nl2br,Image,ScrollTo,SiteModifier,FadeIn,Template,Window,Media,Section,Content,ModifierSection,Item,Map} from '../_core/components';

import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import Vimeo from '../_core/components/Vimeo';
import Content from '../_core/components/Content';
import Nl2br from '../_core/components/Nl2br';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Template from '../_core/components/Template';
import ScrollTo from '../_core/components/ScrollTo';
import Toggle from '../_core/components/Toggle';


// Models
import Asset from '../_core/models/Asset';
import Entry from '../_core/models/Entry';
import Data from '../_core/models/Data';

// Project
import * as Project from '../components';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	mastheadPllx = ({progress}) => {

		if(this.refs['masthead']){
			let offset = 200 * progress;
			window.TweenLite.set(document.getElementById('masthead'),{y:offset+'px'});
		}
	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];
		return (
			
			<Template>

				{/* Scroll to top */}
				<ScrollTo pos={0} duration={0.5} />
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />

				{/* Template */}
				<FadeIn key={entry.id}>
					{blocks.map((block,bix)=>{

						switch(block.type){
							// Masthead
							case 'masthead':
							return (
								<div key={bix}><Project.Masthead asset={block.image} heading={block.heading} subtitle={block.subtitle} textColour={block.textColour} imagePosition={block.imagePosition} textPosition={block.textPosition} /></div>
							)
							// Intro
							case 'intro':
							return (
								<section className={`Section Section--${block.colour}`}>
									{(()=>{
										if(block.heading){
											return (
												<hgroup className="Section-title">
													<div className="Container">
													<h2>{block.heading}</h2>
													</div>
												</hgroup>
											)
										}
									})()}
									
									<div className="Section-body">
										<div className="Container">
											<Content modifier={'intro'} html={block.text} />
										</div>
									</div>
								</section>
							)
							// Dropdowns
							case 'dropdowns':
							return (
								<section className="Section Section--sticky Section--white">
							

									<div className="Section-sticky">
										
											<hgroup className="Section-title">
												<div className="Container">
													<h2><Nl2br text={block.heading} /></h2>
													<h3><Nl2br text={block.subtitle} /></h3>
												</div>
											</hgroup>
										
									</div>

									<div className="Container">
										<div className="Section-body">
										
											<dl className="List List--accordion">
												{block.items.map((item,itemix)=>{

													return (
														<Toggle key={'toggle_'+itemix} tag={'li'}>
															<dt><span><Nl2br text={item.heading} /></span></dt>
															<dd>
																<div onClick={(e)=>{
																	if(item.image && item.image.length){
																		e.stopPropagation();
																	}
																}}>
																<Content  modifier="default" html={item.text} />
																{(()=>{
																	if(item.image && item.image.length){
																		if(item.vimeoId){
																			return (<Asset id={item.image} render={(asset)=>{
																				return <Vimeo poster={asset.url} video={item.vimeoId} ratio={16/9}/> 
																			}} />)
																		}else{
																			return (<Media id={item.image} fluid={false} />)
																		}
																	}
																})()}
																</div>
															</dd>
														</Toggle>
													)

												})}
											</dl>
											<Content modifier="default" html={block.text} />
										</div>
									</div>
								</section>
							)
							// Video
							case 'video':
							return (
								<section className="Section Section--teal Section--video">
							
									<hgroup className="Section-title">
										<div className="Container">
											<h2>{block.heading}</h2>
										</div>
									</hgroup>
									
									<div className="Section-body">
										<div className="Container">
											{(()=>{
												if(block.image && block.image.length){
													if(block.vimeoId){
														return (<Asset id={block.image} render={(asset)=>{
															return <Vimeo poster={asset.url} video={block.vimeoId} ratio={16/9}/> 
														}} />)
													}else{
														return (<Media id={block.image} fluid={false} />)
													}
												}
											})()}
											<Content modifier="default" html={block.text} />
										</div>
									</div>
									
								</section>
							)
							// Text
							case 'text':
							return (
								<section className={`Section Section--sticky Section--${block.colour}`}>
							
									<div className="Section-sticky">
										
											<hgroup className="Section-title">
												<div className="Container">
													<h2><Nl2br text={block.heading} /></h2>
													<h3><Nl2br text={block.subtitle} /></h3>
												</div>
											</hgroup>
										
									</div>

									<div className="Container">
										<div className="Section-body">
											<Content  modifier="default" >
											{(()=>{
												if(block.image && block.image.length){
													
													return (<Asset id={block.image} render={(asset)=>{
														return (
															<figure class="side-right">
																<img src={asset.url} alt={block.caption} />
																<Nl2br text={block.caption} />
															</figure>	
														)
													}} />)
													
												}
											})()}
											<Html html={block.text} />
											</Content>
										</div>
									</div>
								</section>
							)
							// Carousel
							case 'carousel':
							return (
								<section className="Section Section--yellow">
							
									<hgroup className="Section-title">
										<div className="Container">
										<h2>{block.heading}</h2>
										</div>
									</hgroup>
									<div className="Section-body">
										
										<Project.CarouselGallery  settings={{slidesToShow:1,autoplay:true,autoplaySpeed:10000,pauseOnHover:false}} autoplay delay={5000}>
										{block.items.map((e,eix)=>(
											<div key={'q'+eix}>
												<div className="Container">
												<div className="Quote">
												<Content modifier={'intro Content--quote'}>
													<p><em>“{e.quote}”</em></p>
												</Content>
												<div className="Quote-foot">
													
														<div>
															<Content>
																<h3>{e.quoteName}, {e.role}</h3>
																<Html html={e.info} />
															</Content>
														</div>
														
													
												</div>
												</div>
												</div>
											</div>
										))}
										</Project.CarouselGallery>
										
										
									</div>
								</section>
							)
							// Fees
							case 'fees':
							
							return (
								<section className={`Section Section--sticky Section--white`}>
							
									<div className="Section-sticky">
										
											<hgroup className="Section-title">
												<div className="Container">
													<h2><Nl2br text={block.heading} /></h2>
													<h3><Nl2br text={block.subtitle} /></h3>
												</div>
											</hgroup>
										
									</div>

									<div className="Container">
										<div className="Section-body">
											<Content modifier="default" >
											{block.items.map((item,fix)=>{
												return (
												<div className="Item Item--price" key={'fee'+fix}>
													<h3><Nl2br text={item.heading} /> <span className="Item-price">{item.fee}</span></h3>
											  		<p></p>
													<Html html={item.text} />
												</div>
												)
											})}
											</Content>
										</div>
									</div>
								</section>
							)
							// Default
							default:
							return <div key={bix}>{JSON.stringify(block)}</div>

						}

					})}
				</FadeIn>
			</Template>
		
		);

	}
}

export default Default;
