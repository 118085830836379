import GoogleAnalytics from "react-ga";

// Check if production or preview
let IS_PRODUCTION =
	window.location.hostname.match("pattibuttrick.com") &&
	!window.location.hostname.match("gridhosted.co.uk");
IS_PRODUCTION = true;

// Define host, or remote host if running from local
let HOST = IS_PRODUCTION ? "/data/" : "/";
if (window.location.hostname.match("localhost"))
	HOST = "http://proxy.olson.work/?p=https://pattibuttrick.com/data/";

// Set cachebuster
const d = new Date();
const CACHEBUSTER = IS_PRODUCTION
	? "?nc=1.0" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
	: "?nc=1.0" + d.getTime();

// Define manifest & settings
const settings = {
	SITENAME: "Patti Buttrick",

	//GA_ID:'UA-126051290-2',
	GA_ID: "G-L640880Q72", // Update GA May 2022
	MANIFEST: [
		{
			name: "entries",
			src: HOST + "entries.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "assets",
			src: HOST + "assets.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "navigation",
			src: HOST + "navigation.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "filters",
			src: HOST + "filters.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "menus",
			src: HOST + "menus.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "footer",
			src: HOST + "footer.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "globals",
			src: HOST + "globals.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
			transformer: (data) => data[0],
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyCHIGIh56VI-pi__1m5xylpk2JrNeBLEII",
};

if (settings.hasOwnProperty("GA_ID")) {
	GoogleAnalytics.initialize({
		trackingId: settings.GA_ID,
		debug: true,
	});
}

export default settings;
