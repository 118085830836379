import React, {Component} from 'react'
import Data from '../../_core/models/Data'
import DataRouter from '../../_core/controllers/DataRouter'

import Layout from '../../templates/_layout';

class Router extends Component {

  constructor() {
    super()

  }

  

  render () {
      return (
      <DataRouter routes={[
          // Store an ENTRY and HOME global for any URI
          {
            route:'(/.*)/?',
            data : (matches,data)=> {

              let found = false;
              let home = false;

              found = data.entries.find(entry=>(entry.uri == window.location.pathname));
              home = data.entries.find(entry=>(entry.uri == '/'));
              
              return {ENTRY:found,HOME:home}

            }
          },
          // Set a TEMPLATE variable based on a hash value
          // eg /about/#/template/basic/
          {
            route:'#/template/(.*)/?',
            data : (matches,data)=> {

              let found = false;
              let home = false;

              found = data.entries.find(entry=>(entry.uri == matches[1]));
              home = data.entries.find(entry=>(entry.uri == '/'));
              
              return {TEMPLATE:matches[1]}

            }
          },
          ]}>
          <Data require={['HOME']}>
              {()=>(

                  <Layout />
              )}
          </Data>
      </DataRouter>
      )
  }
}

export default Router
