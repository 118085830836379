import React, { Component } from 'react';

import ViewportProgress from '../../_core/components/ViewportProgress';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import Content from '../../_core/components/Content';
import Nl2br from '../../_core/components/Nl2br';


class Masthead extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	mastheadPllx = ({progress}) => {

		if(this.refs['masthead']){
			let offset = 200 * progress;
			window.TweenLite.set(this.refs['masthead'],{y:offset+'px'});
		}
	}

	/*

		RENDER

	*/

	render() {

		return (
			
			<div className={`Template-masthead Template-masthead--${this.props.imagePosition}  Template-masthead--${this.props.textColour} Template-masthead--${this.props.textPosition}`}>
				
				<div data-layout="fluid" ref={'masthead'}>
					<ViewportProgress start={0} onProgress={this.mastheadPllx}>
						<Media id={this.props.asset} fluid />
					</ViewportProgress>
				</div>
				
				<div className="Template-masthead-content">
				<div className="Container">
					<Content modifier={'masthead'}>
						<h2 key={'h1'}><Nl2br text={ this.props.heading  } /></h2>
						<h3 key={'h2'}><Nl2br text={ this.props.subtitle } /></h3>
					</Content>
				</div>
				</div>
			</div>
					
		);

	}
}

export default Masthead;
