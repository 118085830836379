/*

  Docs: https://github.com/akiran/react-slick

*/

import React, {Component} from 'react'
import ReactDOM from "react-dom";
import Slider from "react-slick";

import Icon from '../../_core/components/Icon';
import Base from '../../_core/components/BaseComponent';

import './index.css'

class CarouselGallery extends Component {

  render () {

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    if(this.props.settings) settings = {...settings,...this.props.settings};

    return (

      <div>
        <Base family={"CarouselGallery"} >
        <Slider {...settings}>
          {this.props.children}
        </Slider>
        </Base>
      </div>
    )
  }
}

export default CarouselGallery
